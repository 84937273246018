import React from 'react';
import {graphql, Link} from 'gatsby';
import Helmet from 'react-helmet';
import kebabCase from 'lodash/kebabCase';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout'

class CategoriesRoute extends React.Component {
    render() {
        const {title} = this.props.data.site.siteMetadata;
        const categories = this.props.data.allMarkdownRemark.group;

        return (
            <Layout>
                <div>
                    <Helmet title={`Alle Kategorien - ${title}`}/>
                    <Sidebar {...this.props} />
                    <div className="content">
                        <div className="content__tags-page">
                            <div className="page">
                                <h1 className="page__title">
                                    <span className="white-text-chateau-bg fixed-padding-left-increased">
                                    Alle Kategorien
                                    </span>
                                </h1>
                                <div className="page__body">
                                    <div className="categories">
                                        <ul className="categories__list" lang="de">
                                            {categories.map(category => (
                                                <li key={category.fieldValue} className="categories__list-item">
                                                    <Link to={`/categories/${kebabCase(category.fieldValue)}/`}
                                                          className="categories__list-item-link">
                                                        {category.fieldValue} ({category.totalCount})
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default CategoriesRoute;

export const pageQuery = graphql`
  query CategoryesQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          facebook
          meetup
          xing
          instagram
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;
